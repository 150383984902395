import React, {useState} from "react"
import {getWorkUri} from "../../lib/utils"
import {getImage} from "../../lib/dataManipulation"
import ArchiveItem from "./archiveItem"
import ArchiveSubLi from "./archiveSubLi"

function MainMediumArchive({medium, works}) {
  let [activeSub, setActiveSub] = useState()
  let [activeItem, setActiveItem] = useState()
  medium.children.nodes = medium.children.nodes?.sort((a, b) => a.termOrder + 0 - (b.termOrder + 0))
  return medium.children.nodes.map((child, i) => {
    let attachedWorks = works.filter(work => work.node.mediums.nodes.some(el => el.id === child.id))
    let childWorkIds = []
    attachedWorks.forEach(work => childWorkIds.push(work.node.id)) // moet nog aangepast
    attachedWorks = attachedWorks?.sort((a, b) => new Date(b.node.date) - new Date(a.node.date))
    let isHidden = !!activeSub && activeSub !== child.id
    return (
      <React.Fragment key={i}>
        <ArchiveSubLi sub={child} {...{setActiveSub, isHidden}} />
        {attachedWorks.map(({node: work}, f) => {
          let image = getImage(work.image?.node, "thumbnail")
          let project = work.projects.nodes[0]
          let uri = getWorkUri(work, child.id, project?.id, "medium")
          return (
            <ArchiveItem
              {...{activeItem, setActiveItem, uri, image, isHidden}}
              id={work.id}
              content={work.description.content}
              title={work.title}
              key={f}
            />
          )
        })}
      </React.Fragment>
    )
  })
}

export default MainMediumArchive
