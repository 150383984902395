import {getWorkUri} from "../../lib/utils"
import React, {useState} from "react"
import ArchiveItem from "./archiveItem"
import {getImage} from "../../lib/dataManipulation"

function MediumArchive({works, mediumId, projectId}) {
  let [activeItem, setActiveItem] = useState()
  if (projectId) {
    works = works.filter(work =>
      work.node.projects.nodes.some(el => el.id.toString() === projectId || el.parent?.node.id.toString() === projectId)
    )
  }
  works = works?.sort((a, b) => new Date(b.node.date) - new Date(a.node.date))

  return works.map(({node: work}, i) => {
    let image = getImage(work.image?.node, "thumbnail")
    let project = work.projects.nodes[0]
    let uri = getWorkUri(work, mediumId, project?.id, "medium")
    let {id, title} = work
    return <ArchiveItem {...{activeItem, setActiveItem, image, uri, id, title}} content={work.description?.content} key={i} />
  })
}

export default MediumArchive
